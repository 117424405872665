var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [
              _vm._v(" " + _vm._s(_vm.$t("safety.titles.header")) + " "),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "6" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: { type: "button", to: "/safety/br/kovi" },
                },
                [_vm._v(" KTS BR ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: { type: "button", to: "/safety/br/mobi7" },
                },
                [_vm._v(" Mobi7 BR ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: { type: "button", to: "/safety/mx/kovi" },
                },
                [_vm._v(" KTS MX ")]
              ),
              _c(
                "b-button",
                { attrs: { type: "button", to: "/safety/mx/mobi7" } },
                [_vm._v(" Mobi7 MX ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.displayMap
        ? _c(
            "div",
            [
              _c(
                "b-card",
                { attrs: { "body-class": "p-0" } },
                [
                  _c(
                    "b-card-body",
                    { staticClass: "p-0" },
                    [
                      _c("fleet-position-map", {
                        staticClass: "maps",
                        attrs: {
                          fleet: true,
                          name: "fleet-location",
                          tracking: _vm.fleetTracking,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.$apollo.queries.fleetTracking.loading
        ? _c(
            "div",
            [
              _c(
                "b-card",
                { attrs: { "body-class": "p-0" } },
                [
                  _c("b-card-body", { staticClass: "p-0 not_found" }, [
                    _vm._v(" " + _vm._s(_vm.$t("safety.titles.loading")) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-card",
                { attrs: { "body-class": "p-0" } },
                [
                  _c("b-card-body", { staticClass: "p-0 not_found" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("safety.titles.not_found")) + " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }