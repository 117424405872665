<template>
  <Layout>
    <b-row class="pb-4">
      <b-col cols="6">
        <h2>
          {{ $t('safety.titles.header') }}
        </h2>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button
          type="button"
          to="/safety/br/kovi"
          class="mr-2">
          KTS BR
        </b-button>
        <b-button
          type="button"
          class="mr-2"
          to="/safety/br/mobi7">
          Mobi7 BR
        </b-button>
        <b-button
          type="button"
          to="/safety/mx/kovi"
          class="mr-2">
          KTS MX
        </b-button>
        <b-button
          type="button"
          to="/safety/mx/mobi7">
          Mobi7 MX
        </b-button>
      </b-col>
    </b-row>
    <div v-if="displayMap">
      <b-card body-class="p-0">
        <b-card-body class="p-0">
          <fleet-position-map
            :fleet="true"
            class="maps"
            name="fleet-location"
            :tracking="fleetTracking"
          />
        </b-card-body>
      </b-card>
    </div>
    <div v-else-if="$apollo.queries.fleetTracking.loading">
      <b-card body-class="p-0">
        <b-card-body class="p-0 not_found">
          {{ $t('safety.titles.loading') }}
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <b-card body-class="p-0">
        <b-card-body class="p-0 not_found">
          {{ $t('safety.titles.not_found') }}
        </b-card-body>
      </b-card>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import FleetPositionMap from '@components/fleet-position-map';
import CAR_LIST_TRACKING from '@graphql/car/queries/tracking-list.gql';
import { i18next } from '@src/translate/i18n';

export default {
  name: 'Safety',
  page: {
    title: i18next.t('safety.titles.header'),
  },
  components: {
    Layout,
    FleetPositionMap,
  },
  data: () => ({
    fleetTracking: [],
    timer: 0,
  }),
  computed: {
    displayMap() {
      return [...this.fleetTracking].length > 0;
    }
  },
  apollo: {
    fleetTracking: {
      query: CAR_LIST_TRACKING,
      pollInterval: 40000,
      variables() {
        return {
          country: this.$route.params.country,
          provider: this.$route.params.provider,
        };
      },
      update: data => data.carsTracking,
    },
  },
  beforeDestroy() {
    this.$apollo.queries.fleetTracking.stopPolling()
  }
};
</script>

<style lang="scss" scoped>
  .maps {
    border-radius: 0.25rem;
    min-height: 600px;
    height: 100%;
  }
  .not_found {
    padding: 140px 0px!important;
    text-align: center;
    color: #c0c0c0;
    font-size: 30px;
  }
</style>
